import { instantPayRequest } from 'App/_redux/_services'
import React, { Fragment, useEffect, useState, useCallback } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { QRCode } from 'react-qrcode-logo';
import { DotLoader } from 'react-spinners';
import { Images } from 'App/constants';
import { useScreenDetector } from 'App/hooks/useScreenDetector';

import io from "socket.io-client";
import config from "App/lib/app-config";
import SocketEvent from 'App/context/SocketEvent';
import { MdOutlineError } from "react-icons/md";
import { useSelector } from 'react-redux';
import axios from 'axios'
import moment from 'moment-timezone';
import OtpTimer from 'otp-timer'
import Swal from 'sweetalert2'


const InstantPay = () => {
    const navigate = useNavigate()
    const { state } = useLocation();
    const { currentUser } = useSelector((state) => state.auth);
    const { isMobile } = useScreenDetector()
    const param = useParams()
    // const [state, setState] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isQr, setQr] = useState(false)
    const [connected, setConnected] = useState(false);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    const requestPayIn = async () => {
        const data = {
            amount: param.amount
        }
        setLoading(true)
        try {
            let res = await instantPayRequest(data).then((response) => {
                if (response.data) {
                    // setState(response.data)
                    setLoading(false)
                    checkStatus(response.data)
                    let close = setTimeout(() => {
                        clearTimeout(close)
                        closeWindow()
                    }, 330000)
                    return
                }
                if (response.error) {
                    setLoading(false)
                    navigate(-1)
                    // window.close();
                    // window.opener.location.reload();
                    // window.opener.popupMsg('something went wrong one', false)
                }
            })

        } catch (error) {
            if (error.error != "") {

                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error.error,

                });

                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Transection Successful",
                    showCloseButton: true,
                });


                setError(error.error)
                setTimeout(() => {
                    closeWindow()
                }, 5000)
                clearTimeout()
            }

            // window.close();
            // window.opener.popupMsg('something went wrong', false)}
        }
    }

    const closeWindow = () => {
        // window.close();
        // window.opener.location.reload();
        Swal.close()
        navigate(-1)
    }

    const checkStatus = () => {
        let st = setInterval(() => {
            axios.get(`https://planetctechnology.com/planetcapi/api/v1/checkstatus?merchanttransactionid=${param.merchantTransactionId}&txn_date=${moment(new Date()).format('dd-mm-yyyy')}`).then((res) => {
                if (res.data.status) {
                    if (res.data.status && res.data.status) {
                        // setMessage("success")
                        Swal.fire({
                            icon: "success",
                            title: "Success",
                            text: "Transection Successful",
                        });
                        setTimeout(() => {
                            Swal.close()
                            navigate(-1)
                        }, 5000)
                        clearTimeout()
                        clearInterval(st)

                        return
                    }
                }
            })
        }, 3000)

    }

    useEffect(() => {
        if (state) {
            checkStatus()
            let close = setTimeout(() => {
                clearTimeout(close)
                closeWindow()
            }, 330000)
            return
        }
    }, [])


    useEffect(() => {
        const handlePopState = () => {
            window.location.reload(); // Reload the previous page

        };
        window.addEventListener('popstate', handlePopState);

        // return () => {
        //     window.removeEventListener('popstate', handlePopState);
        // };
    }, []);



    const paymentModule = [
        { image: `${Images.PAYTM}`, type: '2', name: 'PayTM', path: 'paytm://' },
        { image: `${Images.GPAY}`, type: '3', name: 'GPay', path: 'gpay://upi/' },
        { image: `${Images.UPI}`, type: '4', name: 'UPI', path: 'upi://' },
        { image: `${Images.PHONEPE}`, type: '5', name: 'PhonePe', path: 'phonepe://' },
        { image: `${Images.AMAZONPAY}`, type: '6', name: 'AmazonPay', path: 'amazonpay://' },
    ]
    return (
        <Fragment>
            <div className='h-screen w-full z-[150] bg-gray-50 dark:bg-black dark:text-white fixed top-0 left-0 grid justify-center items-center'>
                {error != null ?
                    <div className='text-center text-red-500'>
                        {error}
                    </div>
                    :
                    <Fragment>
                        {message == null ? <div className='text-center justify-center'>
                            {isQr && <div className='flex justify-start cursor-pointer dark:text-black'> <p onClick={() => { setQr(false) }}>Back</p></div>}
                            {loading ?
                                <div className="px-24 py-8 bg-[#F5F5F5] dark:bg-[#22262A] flex justify-center items-center">
                                    <DotLoader color="#0b81db" />
                                </div>
                                :
                                <Fragment>
                                    {state && state.url &&
                                        <Fragment>
                                            <h1 className='font-bold text-xl'>Pay ₹{param.amount}</h1>
                                            <h1 className='font-bold text-[10px]'>Order ID {state.merchantTransactionId}</h1>

                                            {(isMobile && !isQr) &&
                                                <div className='grid grid-cols-2 gap-2 mt-1'>
                                                    {paymentModule.map((item, key) => (
                                                        <div className='border rounded flex justify-center items-center max-w-36'>
                                                            <a href={state.url.replace('upi://', `${item.path}`)}>
                                                                <img src={item.image} alt="" width={100} className='w-full' />
                                                            </a>
                                                        </div>
                                                    ))}
                                                    <div className='border rounded flex justify-center items-center max-w-36'>
                                                        <p onClick={() => { setQr(!isQr) }} href={state.url}>
                                                            <img src={Images.QRCODE} alt="" width={100} className='w-full' />
                                                        </p>
                                                    </div>
                                                </div>}

                                            {(!isMobile || isQr) &&
                                                <QRCode
                                                    value={state.url}
                                                    size="300"
                                                    logoImage={'/FAV-icon.png'}
                                                    logoWidth="80"
                                                    logoHeight="100"
                                                    logoOpacity="0.6"
                                                />}
                                        </Fragment>
                                    }
                                </Fragment>
                            }
                        </div> :
                            message && message.length ? <Fragment>
                                <div className="relative p-4 w-full max-w-md md:h-auto">
                                    <div className="relative p-4 text-center bg-gray-50 rounded-lg shadow dark:bg-gray-800 sm:p-5">
                                        <div className={"w-12 h-12 rounded-full  p-2 flex items-center justify-center mx-auto mb-3.5 " + (message == "success" ? "bg-green-100 dark:bg-green-900" : "bg-red-100 dark:bg-red-900")}>
                                            {message == "success" ? <svg aria-hidden="true" className="w-8 h-8 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
                                                : <MdOutlineError color='red' size={36} />}</div>
                                        <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-gray-50 ">Transection {message == "success" ? "Successful" : "Failed"}.</p>
                                    </div>
                                </div>
                            </Fragment> : null}
                        {state && message == null && <div className='text-center'>
                            <OtpTimer
                                minutes={5}
                                seconds={0}
                                textColor={"red"}
                                background={"transparent"}
                                text=""
                            // ButtonText="Resend"

                            />
                            <p>Please do not refresh page or close </p>
                        </div>}
                    </Fragment>
                }
            </div>
        </Fragment>
    )
}

export default InstantPay
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Promotions = () => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    return (
        <div className="max-w-4xl mx-auto px-4 py-8">
            <h1 className="text-2xl font-bold mb-6">Promotions</h1>
            <section className="mb-8"></section>
        </div>
    )
}

export default Promotions
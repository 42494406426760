import { userBankDetail, withDrawListRequest, withDrawRequest } from 'App/_redux/_services';
import { getDateAndTime } from 'App/utils';
import React, { Fragment, useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { DotLoader } from 'react-spinners';
import CoustomTabel from "../../components/Common/CoustomTabel";
import { useDispatch, useSelector } from "react-redux";
import { getUserBalanceRequest } from 'App/_redux/_actions';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';

const Withdrawal = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { currentUser } = useSelector((state) => state.auth);
    const [state, setState] = useState({
        bank_name: '',
        acc_name: '',
        acc_number: '',
        ifsc: '',
        account_type: '',
        branch: '',
        amount: ''

    })

    const [errors, setError] = useState(null)
    const [loadingAcc, setLoadingAcc] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataList, setDataList] = useState([]);

    useEffect(() => {
        fetchAccountDetails()
        getwithDrawList();
    }, [])


    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setState((s) => ({ ...s, [name]: value }));
        setError((s) => ({ ...s, [name]: null }));

    };


    const fetchAccountDetails = async () => {
        setLoadingAcc(true)
        try {
            const res = await userBankDetail().then((response) => {
                if (response.data != '') {
                    let obj = response.data
                    setState((s) => ({ ...s, ...obj }));
                    setLoadingAcc(false)
                } else {
                    setLoadingAcc(false)
                }
            })
        } catch (error) {
            if (error.error != '') {
                toast.error("Something went wrong.")
                setLoadingAcc(false)
            }
        }
    }

    const getwithDrawList = async () => {
        setLoading(true)
        try {
            const res = await withDrawListRequest().then((response) => {
                setLoading(false)
                if (response.data != '') {
                    setDataList(response.data);
                    return
                }
            })
        } catch (error) {
            setLoading(false)
            if (error.error != '') {
                toast.error("Something went wrong.")
            }
        }
    }

    const withdrawRequest = async () => {
        if (state.amount == "" && state.amount <= 0) {
            toast.error("Please enter amount.")
            document.getElementById('amount').focus()
            return
        }
        try {
            setLoading(true)
            const res = await withDrawRequest(state).then((response) => {
                if (response.message != '') {
                    toast.success(response.message)
                    getwithDrawList();
                    getBalance();
                    setLoading(false)
                    setState((s) => ({ ...s, amount: '' }));
                    return
                }
            })
        } catch (error) {
            setLoading(false)
            if (error.error != '') {
                toast.error(error.error)
            }
        }
    }

    const getBalance = () => {
        dispatch(getUserBalanceRequest((response) => {
            if (response) {
                let currentUser_ = JSON.parse(localStorage.getItem("currentUser"));
                currentUser_.balance = response.balance;
                currentUser_.exposure = response.exposure;
                currentUser_.bonus_balance = response.bonus_balance;
                currentUser_.withdrawal_balance = response.withdrawal_balance;

                currentUser.balance = response.balance;
                currentUser.exposure = response.exposure;
                currentUser.bonus_balance = response.bonus_balance;
                currentUser.withdrawal_balance = response.withdrawal_balance;
                localStorage.setItem("currentUser", JSON.stringify(currentUser_));
            }
        }))
    }
    const columnData = [
        { name: "Date", value: "created_at" },
        { name: "Bank Name", value: "bank_name" },
        { name: "Account Number", value: "acc_number" },
        { name: "IFSC", value: "ifsc" },
        { name: "Mobile", value: "mobile" },
        { name: "Amount", value: "amount" },
        { name: "Payout Amount", value: "payout_amount" },
        { name: "status", value: "status" },
        { name: "Remark", value: "remark" },
    ];

    const columnDataList = (data) => {
        return data && data?.length > 0
            ? data?.map((item) => ({
                ...item,
                status: item.status == 0 ? 'Pending' : item.status == 1 ? "Completed" : item.status == 2 ? "Rejected" : item.status == 4 ? "In Progress" : "",
                created_at: getDateAndTime("MMMM DD, hh:mm A", item?.created_at),
            }))
            : [];
    };


    return (
        <div className="innrRight_singleBx">
            <div className="bg-secondary text-secondary-foreground p-[10px_18px_10px_18px] xl:p-[10px_20px_10px_30px] rounded">
                <h4 className="h4_semiTxt flex items-center gap-2"><AiOutlineArrowLeft className=' cursor-pointer' onClick={() => navigate(-1)} /> Withdrawal Balance ({currentUser.withdrawal_balance}₹)</h4>

                {!loadingAcc && <Fragment>
                    {state.acc_number ?
                        <div className="filter_box filter_boxGrid">
                            <div className="filterFx_basis ">
                                <input type="text"
                                    name="bank_name" id=""
                                    placeholder='Bank Name'
                                    value={state.bank_name}
                                    disabled
                                    className="cstFrm_inputs form-control" />
                            </div>
                            <div className="filterFx_basis ">
                                <input type="text"
                                    name="acc_name" id=""
                                    placeholder='Account Holder Name'
                                    value={state.acc_name}
                                    disabled
                                    className="cstFrm_inputs form-control" />
                            </div>

                            <div className="filterFx_basis ">
                                <input type="number"
                                    name="acc_number" id=""
                                    placeholder='Account Number'
                                    value={state.acc_number}
                                    disabled
                                    className="cstFrm_inputs form-control" />
                            </div>

                            <div className="filterFx_basis ">
                                <input type="text"
                                    name="ifsc" id=""
                                    placeholder='IFSC Code'
                                    value={state.ifsc}
                                    disabled
                                    className="cstFrm_inputs form-control" />
                            </div>

                            <div className="filterFx_basis ">
                                <input type="text"
                                    name="account_type" id=""
                                    placeholder='Account Type'
                                    value={state.account_type}
                                    disabled
                                    className="cstFrm_inputs form-control" />
                            </div>

                            <div className="filterFx_basis ">
                                <input type="text"
                                    name="branch" id=""
                                    placeholder='Branch'
                                    value={state.branch}
                                    disabled
                                    className="cstFrm_inputs form-control" />
                            </div>

                            <div className="filterFx_basis filterFx_basis_full">
                                <input
                                    type="number"
                                    name="amount"
                                    id="amount"
                                    placeholder='Amount'
                                    value={state.amount}
                                    onChange={handleChange}
                                    className="cstFrm_inputs form-control" />
                                {errors && errors.amount && <small className='text-red-500'>{errors.amount}</small>}
                            </div>

                            <div className="d-flxe gap-1 filterFx_basis filterFx_basis_full" >
                                <button type="button" className="_btnSave h-full" disabled={loading} onClick={() => withdrawRequest()}>Withdraw</button>
                            </div>
                        </div>
                        :
                        <div className="bg-secondary text-secondary-foreground p-[10px_18px_10px_18px] xl:p-[10px_20px_10px_30px] rounded">
                            <div className='w-full flex justify-center items-center h-36'>
                                <Link to={'/bank-account'} className='bg-red-500 text-white rounded py-2 px-4 animate-pulse'>Add your account details →</Link>
                            </div>
                        </div>
                    }
                </Fragment>
                }
            </div>

            {loading ?
                <div className="flex justify-center items-start text-center py-12">
                    <div className="px-24 py-8 bg-secondary flex justify-center items-center">
                        <DotLoader color="#0b81db" />
                    </div>
                </div>
                :
                <div className="table_controler">
                    <CoustomTabel
                        columnData={columnData}
                        dataList={columnDataList(dataList)}
                    />
                </div>}
        </div>
    );
};

export default Withdrawal
import React, { useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { BsChevronRight } from "react-icons/bs";
import { Images } from "App/constants";
import { logoutUser } from "App/_redux/_actions";
import { useDispatch, useSelector } from "react-redux";
import _ from "App/helper/_";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { MdOutlineNightsStay, MdOutlineWbSunny } from "react-icons/md";

const UserProfile = () => {
    const [formValue, setFormValue] = useState({});
    const { currentUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChange = (e) => {

        const { id, value } = e.target;

        setFormValue((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const logout = () => {
        dispatch(logoutUser((res) => {
            navigate("/login")
        }))
    }

    const [Theme, setTheme] = useState(localStorage.getItem('theme'))

    const settheme = (theme) => {
        localStorage.setItem("theme", theme)
        setTheme(theme)
    }
    const toggleDarkmode = () => {
        let mode = localStorage.getItem('theme')
        if (mode == "dark") {
            settheme("light")
        } else {
            settheme("dark")
        }
        document.body.classList.toggle('dark');
    }



    return (
        <div className="userProfile_main">
            {/* Back Btn */}
            <div onClick={() => navigate(-1)} className="backOptions ">
                <AiOutlineArrowLeft />
                <p>Profile</p>
            </div>
            {/* Back Btn */}

            <div className="h-full overflow-auto pb-[152px]">
                <div className="text-center py-[20px]">
                    <img className="mx-auto" src={Images.PROFILE_USERICON} alt="" />
                    <h2 className="text-[24px]  leading-4 tracking-[0.4px] font-medium m-[16px_0_10px]">
                        {!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? (currentUser.first_name ? currentUser.first_name : currentUser.username) : ""}</h2>
                    {/* <p className="text-[16px] text-[#000000] leading-4 tracking-[0.4px] font-medium opacity-50">User ID 234783</p> */}
                </div>

                <div className="balBx_otr">
                    <div className="balBx_innr">
                        <h4>{currentUser.exposure}</h4>
                        <p>Exposure</p>
                    </div>

                    <div className="balBx_innr">
                        <h4>{currentUser.balance}</h4>
                        <p>Balance</p>
                    </div>

                    {/* <div className="balBx_innr">
                        <h4>{currentUser.bonus_balance}</h4>
                        <p>Bonus</p>
                    </div> */}
                </div>

                <div>
                    <ul>
                        <li className="text-xs leading-4  tracking-[0.4px] uppercase px-[26px] py-[5px]">Profile Management</li>

                        {/* <li>
                            <Link to={""} className="border-y border-secondary bg-primary text-primary-foreground   flex items-center p-[12px_12px_12px_26px] justify-between min-h-[48px]">
                                <span className="text-base leading-4 tracking-[0.4px]  ">Profile Update</span>
                                <BsChevronRight />
                            </Link>
                        </li> */}

                        <li>
                            <Link to={"/change-password"} className="border-y border-t-0 border-secondary bg-primary text-primary-foreground   flex items-center p-[12px_12px_12px_26px] justify-between min-h-[48px]">
                                <span className="text-base leading-4 tracking-[0.4px]  ">Change Password</span>
                                <BsChevronRight />
                            </Link>
                        </li>

                        <li>
                            <Link to={"/bank-account"} className="border-y border-t-0 border-secondary bg-primary text-primary-foreground   flex items-center p-[12px_12px_12px_26px] justify-between min-h-[48px]">
                                <span className="text-base leading-4 tracking-[0.4px]  ">Bank Account</span>
                                <BsChevronRight />
                            </Link>
                        </li>

                        <li>
                            <Link to={"/setting"} className="border-y border-t-0 border-secondary bg-primary text-primary-foreground   flex items-center p-[12px_12px_12px_26px] justify-between min-h-[48px]">
                                <span className="text-base leading-4 tracking-[0.4px]  ">Odd Settings</span>
                                <BsChevronRight />
                            </Link>
                        </li>
                    </ul>

                    <ul>
                        <li className="text-xs leading-4  tracking-[0.4px] uppercase px-[26px] py-[8px]">Fund Management</li>

                        {/* <li>
                            <Link to={"/bonus"} className="border-y border-secondary bg-primary text-primary-foreground   flex items-center p-[12px_12px_12px_26px] justify-between min-h-[48px]">
                                <span className="text-base leading-4 tracking-[0.4px]  ">Bonus</span>
                                <BsChevronRight />
                            </Link>
                        </li> */}
                        <li>
                            <Link to={"/deposit"} className="border-y border-secondary bg-primary text-primary-foreground   flex items-center p-[12px_12px_12px_26px] justify-between min-h-[48px]">
                                <span className="text-base leading-4 tracking-[0.4px]  ">Deposit</span>
                                <BsChevronRight />
                            </Link>
                        </li>

                        <li>
                            <Link to={"/withdrawal"} className="border-y border-t-0 border-secondary bg-primary text-primary-foreground   flex items-center p-[12px_12px_12px_26px] justify-between min-h-[48px]">
                                <span className="text-base leading-4 tracking-[0.4px]  ">Withdrawal</span>
                                <BsChevronRight />
                            </Link>
                        </li>

                    </ul>

                    <ul>
                        <li className="text-xs leading-4  tracking-[0.4px] uppercase px-[26px] py-[8px]">Statement</li>

                        <li>
                            <Link to={"/profit-loss"} className="border-y border-secondary bg-primary text-primary-foreground   flex items-center p-[12px_12px_12px_26px] justify-between min-h-[48px]">
                                <span className="text-base leading-4 tracking-[0.4px]  ">Profit and Loss</span>
                                <BsChevronRight />
                            </Link>
                        </li>

                        <li>
                            <Link to={"/statement"} className="border-y border-t-0 border-secondary bg-primary text-primary-foreground   flex items-center p-[12px_12px_12px_26px] justify-between min-h-[48px]">
                                <span className="text-base leading-4 tracking-[0.4px]  ">Account Statement</span>
                                <BsChevronRight />
                            </Link>
                        </li>

                        <li>
                            <Link to={"/bet-history"} className="border-y border-t-0 border-secondary bg-primary text-primary-foreground   flex items-center p-[12px_12px_12px_26px] justify-between min-h-[48px]">
                                <span className="text-base leading-4 tracking-[0.4px]  ">Bet History</span>
                                <BsChevronRight />
                            </Link>
                        </li>

                        <li>
                            <div className="border-y border-t-0 border-secondary bg-primary text-primary-foreground   flex items-center p-[12px_12px_12px_26px] justify-between min-h-[48px]">
                                <span className="text-base leading-4 tracking-[0.4px]  ">Dark Mode</span>

                                <div>
                                    <input type="checkbox" className='darkmode_toggle hidden'
                                        checked={Theme == "dark" ? true : false}
                                        id="darkmode-toggle"
                                        onClick={toggleDarkmode}
                                    />
                                    <label for="darkmode-toggle" className='darkmode_lable'>
                                        <MdOutlineWbSunny size={22} className="sun" />
                                        <MdOutlineNightsStay size={22} className="moon" />
                                    </label>
                                </div>
                            </div>
                        </li>

                    </ul>

                    <button onClick={() => logout()} className="bg-[#E9113C] m-[24px_auto_0px] block w-full rounded-[5px] text-base leading-4 tracking-[0.4px] text-[#fff] max-w-[268px] min-h-[48px]">Logout</button>
                    <br />
                    <br />
                </div>
            </div>
        </div>
    );
};

export default UserProfile
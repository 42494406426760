import React, { useState, useEffect, Fragment } from "react";
import { getMasterDataRequest, getGlobalAnnouncementRequest, getS3MasterDataRequest, getUserBalanceRequest, logoutUser, toggleAuthModalRequest, getBannersRequest, domainDataRequest, } from "App/_redux/_actions"
import { Menu, Transition } from '@headlessui/react'
import { UserLimitModal } from "App/modals"
import { getStorage } from "App/helper/storage";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { Images } from "App/constants";
import { NavLink } from "react-router-dom";
import { constant } from "App/helper/constant";
import { MdOutlineNightsStay, MdOutlineWbSunny } from "react-icons/md"
import { getUserLimit, getclientBalance, } from "App/_redux/_services";
import _ from "App/helper/_";
import { BsChevronDown } from "react-icons/bs";
import { FaAngleDown, FaSyncAlt, FaUser } from "react-icons/fa";
import { isWebview } from "App/utils";
import { FaDownload } from "react-icons/fa";
import { FaDiceFive, FaPlay } from "react-icons/fa6";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = () => {
  const [Theme, setTheme] = useState(localStorage.getItem('theme'))
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { currentUser, domainData } = useSelector((state) => state.auth);
  const { userBalance, masterData, GlobalAnnouncement, bannerList } = useSelector((state) => state.match);
  const [showUserLimitModal, setUserLimitModal] = useState(false)
  const [userLimit, setUserLimit] = useState([]);

  let currentUserLocal = getStorage("currentUser");
  const settheme = (theme) => {
    localStorage.setItem("theme", theme)
    setTheme(theme)
  }
  const toggleDarkmode = () => {
    let mode = localStorage.getItem('theme')
    if (mode == "dark") {
      settheme("light")
    } else {
      settheme("dark")
    }
    document.body.classList.toggle('dark');
  }

  // useEffect(() => {
  //   let mode = localStorage.getItem('theme')
  //   if (!mode) {
  //     settheme("dark")
  //   }
  // }, []);


  const getUserLimitDetails = async () => {
    try {
      const res = await getUserLimit();
      setUserLimit(res?.data?.limit_bets);
      setUserLimitModal(true)
    } catch (error) { }
  };

  useEffect(() => {
    let param = { host: window.location.hostname }
    if (domainData == null) {
      dispatch(domainDataRequest(param, (res) => {
        if (res?.data) {
          document.title = res?.data?.website_name;
          let link = document.querySelector("link[rel~='icon']");
          if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.getElementsByTagName('head')[0].appendChild(link);
          }
          link.href = res?.data?.fav_icon;
        }
      }))
    }
  }, [])


  useEffect(() => {
    if (masterData == null) {
      dispatch(getS3MasterDataRequest({}, (res) => {
        if (_.isUndefined(res)) {
          dispatch(getMasterDataRequest({}, (res) => { }))
        }
      }))
    }
  }, [])


  useEffect(() => {
    if (currentUserLocal && currentUser.username) {
      // getclientBalanceData();
      if (userBalance == null) {

        dispatch(getUserBalanceRequest((response) => {
          if (response) {
            let currentUser_ = JSON.parse(localStorage.getItem("currentUser"));
            currentUser_.balance = response.balance;
            currentUser_.exposure = response.exposure;
            currentUser_.bonus_balance = response.bonus_balance;
            currentUser_.withdrawal_balance = response.withdrawal_balance;

            currentUser.balance = response.balance;
            currentUser.exposure = response.exposure;
            currentUser.bonus_balance = response.bonus_balance;
            currentUser.withdrawal_balance = response.withdrawal_balance;
            localStorage.setItem("currentUser", JSON.stringify(currentUser_));
          }
        }))
      }

    }
    if (GlobalAnnouncement == null) {
      dispatch(getGlobalAnnouncementRequest((response) => {
        if (response && response.data) {

        }
      }))
    }
  }, []);

  useEffect(() => {
    if (bannerList == null || pathname == "/all-sports") {
      let param = {
        type: "Exchange"
      }
      dispatch(getBannersRequest(param, (response) => {
        if (response && response.data) { }
      }))
    }
  }, []);

  const getclientBalanceData = async () => {

    getclientBalance().then((response) => {
      if (response && response.data) {
        let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        currentUser.balance = response.data.balance;
        currentUser.exposure = response.data.exposure;
        localStorage.setItem("currentUser", JSON.stringify(currentUser));
      }
    });
  };

  const logout = () => {
    dispatch(logoutUser((res) => {
      navigate("/login")
    }))
  }

  const toggleUserLimitModal = () => {
    setUserLimitModal(!showUserLimitModal)
  }

  const UserLimitModalProps = {
    showUserLimitModal,
    userLimit,
    toggle: () => toggleUserLimitModal()
  }

  useEffect(() => {
    if (currentUser && currentUser.reset_password && currentUser.reset_password == "1") {
      navigate('/change-password');
    }
  }, [])

  const navItem = [
    // { name: "In play", icon: <BiSolidCricketBall />, link: '/inplay', },
    // { name: "Upcoming", icon: <PiSoccerBallFill />, link: '/upcoming' },
    { name: "Exchange", icon: <FaPlay />, link: '/all-sports' },
    { name: "Casino", icon: <FaDiceFive />, link: !_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? '/casino' : '/casino-market' },
    /* { name: "Promotions", icon: Images.GIFT, link: '/' },*/
  ]


  function toggleClass() {
    let element = document.getElementById('sideBar_bx');
    element.classList.toggle('custom_toggleClass');
  }


  const toggleAuthModal = (type) => {
    dispatch(toggleAuthModalRequest(type))
  }


  return (
    <header className="m-0 fixed top-0 left-0 w-full z-[99]">
      <nav className="header-nav relative z-[9] w-full">
        <div className="max-w-[1440px] flex m-auto h-[56px] items-center ">
          <div className="max-w-[160px] min-w-[160px] lg:max-w-[252px] lg:min-w-[252px] flex items-center gap-[9px] pl-[6px]">

            <div className="block lg:hidden ">
              <button onClick={() => toggleClass()} className="text-white">
                <img src={Images.HAMICON} alt="" />
              </button>
            </div>

            {/* <Link to={currentUserLocal?.username ? "/" : "/login"}>
              <img className="w-[136px]" src={Images.ICON} alt="" />
            </Link> */}
            <Link to={currentUserLocal?.username ? "/" : "/login"}>
              <img className="w-[136px]" src={domainData && domainData.logo ? domainData.logo : Images.ICON} alt="" />
            </Link>
          </div>

          <div className="flex mx-auto justify-end lg:justify-between items-center w-full h-full">
            <div className="lg:flex h-full hidden pl-6">
              {navItem.map((item, index) => {
                return (
                  <NavLink to={item.link} className={({ isActive }) => `MainNavBtn ${isActive ? "MainNavBtnActive" : ""}`}>
                    {item.icon}{item.name}
                  </NavLink>
                )
              })}

            </div>
            <div className="flex items-center !gap-[6px] sm:!gap-5 pr-1">


              {/* <div className="bg-[#F2D61D] p-1 rounded-full dark:bg-[#262626] dark:border dark:border-solid dark:border-white dark:text-white" onClick={toggleDarkmode}>
                {Theme == "dark" ? <MdOutlineWbSunny size={22} className="sun" />
                  : <MdOutlineNightsStay size={18} className="moon " />}
              </div> */}

              {/* {!isWebview && <a href="https://dhan-app.betexchange.guru/velocity999.apk" arget="_blank" rel="noopener noreferrer"
                className=" text-[#191919] flex justify-center items-center w-8 h-9 lg:hidden rounded-[5px] font-semibold text-xs md:text-base relative overflow-hidden uppercase bg-[#f2d61d]" >
                <span className="relative z-10">
                  <FaDownload />
                </span>
              </a>} */}

              {currentUserLocal?.username ? (
                <div className="flex items-center flex-row-revers md:!flex-row">
                  <div className="h-full">
                    <NavLink to={"/deposit"} className="addFund_btn">
                      <span className="md:hidden">Deposit</span>
                      <span className="hidden md:block">Deposit</span>
                    </NavLink>
                  </div>

                  <div className="ml-[8px] mr-2 hidden md:block w-[126px]">
                    <Menu as="div" className="relative inline-block text-left w-full">
                      <div className="w-full">
                        <Menu.Button className="flex !gap-1 sm:!gap-3 items-center w-full">
                          <div className="bg-primary border-[1px] border-active flex items-center p-[3px_7px] min-h-9 rounded w-full justify-between">
                            <div className="flex items-center">
                              <svg className=" mr-1 w-[18px] h-[18px]" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                <path className=" stroke-active dark:stroke-active-foreground" _ngcontent-loi-c56="" d="M15.6741 11.8635C15.2775 12.2507 15.0509 12.8078 15.1075 13.4027C15.1925 14.4225 16.1273 15.1685 17.1472 15.1685H18.9413V16.2922C18.9413 18.2468 17.3455 19.8427 15.3908 19.8427H4.55049C2.59583 19.8427 1 18.2468 1 16.2922V9.93721C1 7.98256 2.59583 6.38672 4.55049 6.38672H15.3908C17.3455 6.38672 18.9413 7.98256 18.9413 9.93721V11.297H17.0338C16.505 11.297 16.0235 11.5047 15.6741 11.8635Z" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path className=" stroke-active dark:stroke-active-foreground" _ngcontent-loi-c56="" d="M1 10.7869V6.47157C1 5.34788 1.68932 4.34691 2.73747 3.95031L10.235 1.11747C11.4059 0.673663 12.6618 1.54243 12.6618 2.79832V6.38656" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path className=" stroke-active dark:stroke-active-foreground" _ngcontent-loi-c56="" d="M19.9449 12.261V14.2062C19.9449 14.7256 19.5294 15.1505 19.0006 15.1694H17.1498C16.13 15.1694 15.1952 14.4234 15.1102 13.4036C15.0535 12.8087 15.2801 12.2515 15.6767 11.8644C16.0261 11.5056 16.5077 11.2979 17.0365 11.2979H19.0006C19.5294 11.3167 19.9449 11.7416 19.9449 12.261Z" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path className=" stroke-active dark:stroke-active-foreground" _ngcontent-loi-c56="" d="M5.25 10.4004H11.8599" stroke="stoke-active stroke-active-foreground" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>
                              <p className=" text-active dark:text-active-foreground text-[12px]">{Number(currentUser?.balance).toFixed(2)}</p>
                            </div>

                            <div className="bg-active text-xs rounded-full p-[2px]">
                              <BsChevronDown />
                            </div>
                          </div>
                        </Menu.Button>
                      </div>

                      <Transition
                        // as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-1 w-56 origin-top-right rounded-md bg-primary text-primary-foreground shadow-lg ring-1 ring-primary ring-opacity-5 focus:outline-none">

                          <Menu.Item >
                            <div className=" flex justify-between items-center text-xs p-[3px_8px]">
                              <p>Withdrawable (₹)</p>
                              <p>{Number(currentUser?.withdrawal_balance).toFixed(2)}</p>
                            </div>
                          </Menu.Item>

                          <Menu.Item >
                            <div className=" flex justify-between items-center text-xs p-[3px_8px]">
                              <p>Exposure (₹)</p>
                              <p>{Number(currentUser?.exposure).toFixed(2)}</p>
                            </div>
                          </Menu.Item>
                          {/* <Menu.Item >
                            <div className=" flex justify-between items-center text-xs p-[3px_8px]">
                              <p>Bonus (₹)</p>
                              <p>{currentUser?.bonus_balance}</p>
                            </div>
                          </Menu.Item> */}

                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>

                  <div className="capitalize">
                    <p className="text-[10px] sm:text-sm text-active-foreground font-semibold text-right hidden md:block">{currentUser?.username}</p>

                    <div className="exposureMob_bx md:hidden rounded p-[3px_7px] mr-[6px]">
                      <div className="flex gap-[7px] sm:gap-[11px] justify-between">
                        <p className=" text-xs opacity-50 leading-[14px]  widtControl1">Balance</p>
                        <p className=" text-xs leading-[14px] ">{Number(currentUser?.balance).toFixed(2)}</p>
                      </div>
                      <div className="flex gap-[7px] sm:gap-[11px] justify-between"
                        onClick={() =>
                          currentUser?.exposure > 0 &&
                          getUserLimitDetails()
                        }
                      >
                        <p className=" text-xs opacity-50 leading-[14px]  widtControl2">Exposure</p>
                        <p className="text-active text-xs leading-[14px] underline" >{Number(currentUser?.exposure).toFixed(2)}</p>
                      </div>
                    </div>

                    <div className="font-[500] text-[9px] sm:text-xs text-active hidden md:hidden ">
                      <p className=" flex">Balance: {Number(currentUser?.balance).toFixed(2)} <span className="hidden md:block mx-1"> |</span> </p>
                      <p className="underline cursor-pointer text-right md:text-left" onClick={() =>
                        currentUser?.exposure > 0 &&
                        getUserLimitDetails()
                      }
                      >Exposure: {Number(currentUser?.exposure).toFixed(2)}</p>
                    </div>
                  </div>

                  <div className="ml-[10px] sm:ml-[12px] hidde md:block">
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="flex !gap-1 sm:!gap-3 items-center">
                          <div className="userAvtar_bx ">
                            {/* <img
                              className="absolute w-[15px] sm:w-auto"
                              src={Images.USER_ICON}
                              alt=""
                            /> */}

                            <FaUser className="text-active dark:text-active-foreground" size={25} />

                          </div>
                          <FaAngleDown className="text-active-foreground" size={20} />
                        </Menu.Button>
                      </div>

                      <Transition
                        // as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-72 md:w-56 origin-top-right rounded-md bg-secondary text-secondary-foreground shadow-lg ring-1 ring-secondary ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <div className="block px-4 py-2 text-sm">
                              <h1>Welcome <span className="font-bold">
                                {currentUser?.first_name
                                  ? `${currentUser.first_name} ${currentUser.last_name || ''}`
                                  : currentUser?.username}
                              </span></h1>
                              <div className="mt-3 flex justify-between items-center">
                                <p>Available Credit:</p>
                                <p>{Number(currentUser?.balance).toFixed(2)}</p>
                              </div>
                              <div className="flex justify-between items-center">
                                <p>Exposure:</p>
                                <p>{Number(currentUser?.exposure).toFixed(2)}</p>
                              </div>

                              <div className="flex justify-between items-center w-full gap-1 mt-2">
                                <div className="flex-1 bg-active text-active-foreground rounded-md py-1 text-center"><Link to={'/deposit'}>Deposit</Link></div>
                                <div className="flex-1 bg-active text-active-foreground  rounded-md py-1 text-center"><Link to={'/withdrawal'}>Withdraw</Link></div>
                              </div>
                            </div>
                            {constant.menuItems.map((item) => (
                              <Menu.Item key={item?.name}>
                                <NavLink
                                  to={item?.link}
                                  className={classNames(
                                    "",
                                    "block px-4 py-2 text-sm hover:bg-primary"
                                  )}
                                >
                                  {item?.name}
                                </NavLink>
                              </Menu.Item>
                            ))}
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  type="button"
                                  onClick={() => logout()}
                                  className={classNames(
                                    active
                                      ? ""
                                      : "",
                                    "block w-full px-4 py-2 text-left text-sm hover:bg-primary"
                                  )}
                                >
                                  Sign out
                                </button>
                              )}
                            </Menu.Item>
                            {/* </form> */}
                            <Menu.Item>
                              <Fragment>
                                <div className="flex  hover:bg-primary w-full px-4 py-2 text-left text-sm justify-between">
                                  <div className="text-sm ">Dark Mode</div>
                                  <div>
                                    <input type="checkbox" className='darkmode_toggle hidden'
                                      checked={Theme == "dark" ? true : false}
                                      id="darkmode-toggle"
                                      onClick={toggleDarkmode}
                                    />
                                    <label for="darkmode-toggle" className='darkmode_lable'>
                                      <MdOutlineWbSunny size={22} className="sun" />
                                      <MdOutlineNightsStay size={22} className="moon" />
                                    </label>
                                  </div>
                                </div>
                              </Fragment >
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>

                  {isWebview && <div className="mr-3" onClick={() => { window.location.reload() }}><FaSyncAlt color="#fff" /></div>}
                </div>
              ) : (
                <Fragment>

                  <button className=" text-[#CFD4D8] w-[50px] md:w-24 h-9 rounded-[5px] font-semibold text-xs md:text-base relative overflow-hidden uppercase" onClick={() => toggleAuthModal("LOGIN")}>
                    <span className="relative z-10">
                      Login
                    </span>
                  </button>

                  <button className="anvLogin_btn text-[#262626] min-w-[70px] md:w-24 h-9 rounded-[5px] font-semibold text-xs md:text-base relative overflow-hidden text-nowrap" onClick={() => toggleAuthModal("REGISTER")}>
                    <span className="relative z-10 px-2">REGISTER</span>
                  </button>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </nav>
      {showUserLimitModal && <UserLimitModal {...UserLimitModalProps} />}
    </header>
  );
};

export default Header;

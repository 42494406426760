import React, { Fragment, useState } from 'react'
import {
    getDeclaredSession,
} from "App/_redux/_services";
import _ from "App/helper/_"
import { Utils } from "App/utils"

const DeclaredMarkets = ({ event_id }) => {

    const [state, setAllStateValue] = useState({
        toggleMarket: false,
        is_loading: false,
        declaredSession: [],
        declaredSessionTotal: 0
    });

    const setState = (obj, callback) => {
        setAllStateValue((s) => ({ ...s, ...obj }));
        if (callback) callback();
    };

    const getdeclaredSession = () => {
        let param = {
            "event_id": event_id,
        }
        setState({ toggleMarket: !toggleMarket, is_loading: true, declaredSessionTotal: 0 })

        if (!toggleMarket) {
            getDeclaredSession(param).then(response => {
                // if (!_.isEmpty(response) && response.data && !_.isEmpty(response.data) && response.data.length > 0) {
                //     let session = _.map(response.data, (declared_session) => {
                //         return declared_session.profit_loss > 0 ? (Number(declared_session.profit_loss) - Number(declared_session.commission)) : (Number(declared_session.profit_loss) + Number(declared_session.commission))
                //     })
                //     setState({ declaredSessionTotal: this.state.declaredSessionTotal + _.sum(session) })
                // }
                setState({
                    declaredSession: response.data,
                    is_loading: false,
                })
            }).catch(error => {
                setState({ is_loading: false })
            });
        }
    }



    const { toggleMarket, declaredSession, is_loading } = state

    return (
        <Fragment>
            {
                <Fragment>

                    <div className="declareFancy">
                        <div className="declareFancy_innr anvScor_bord_bet">
                            <div>
                                <h2 className="declPara">Declared Session</h2>
                            </div>
                            <div onClick={() => getdeclaredSession()} className='cursor-pointer'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
                                    <path d="M1.48785 2.00005L7.01228 7.97756L12.4878 1.95517" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </Fragment >}


            {toggleMarket && (
                <Fragment>
                    {is_loading ?
                        <p className='dark:text-white text-center text-sm py-1'>Loading...</p>
                        :
                        <Fragment>
                            {declaredSession.length > 0 ? <div className="overflow-auto rounded-[0_0_8px_8px]">
                                <table className="w-full anvWin_table overflow-auto ">
                                    <thead>
                                        <tr className=" text-nowrap">
                                            <th className="firstTd_tab tabTd_border">
                                                <div className="">
                                                    <p className="mediumPara text-color">S.NO</p>
                                                </div>
                                            </th>
                                            <th className="firstTd_tab tabTd_border">
                                                <div className="">
                                                    <p className="mediumPara text-color">MARKET NAME</p>
                                                </div>
                                            </th>

                                            <th className="firstTd_tab tabTd_border">
                                                <div className="">
                                                    <p className="mediumPara text-color">PROFIT LOSS</p>
                                                </div>
                                            </th>
                                            <th className="firstTd_tab tabTd_border">
                                                <div className="">
                                                    <p className="mediumPara text-color">RESULT</p>
                                                </div>
                                            </th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            _.map(declaredSession, (item, idx) => {

                                                return (
                                                    <tr key={idx} className=''>
                                                        <td className="firstTd_tab tabTd_border">
                                                            <div>
                                                                <p className="mediumPara text-color ">{idx + 1}</p>
                                                            </div>
                                                        </td>

                                                        <td className="firstTd_tab tabTd_border ">
                                                            <div>
                                                                <p className="mediumPara text-color ">
                                                                    {item.market_name}
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td className="firstTd_tab tabTd_border ">
                                                            <div>
                                                                <p className="mediumPara text-color ">{item.profit_loss}</p>
                                                            </div>
                                                        </td>
                                                        <td className="firstTd_tab tabTd_border ">
                                                            <div>
                                                                <p className="mediumPara text-color ">{item.settlement_result}</p>
                                                            </div>
                                                        </td>

                                                    </tr>

                                                )
                                            })
                                        }




                                    </tbody>
                                </table>
                            </div> : <p className='text-color text-center text-sm py-1'>No Record Found.</p>}
                        </Fragment>
                    }
                </Fragment>
            )
            }
        </Fragment >
    )
}

export default DeclaredMarkets
import config from "App/lib/app-config";

const URL = config.api_baseurl

const API = {

    // Auth
    LOGIN: `${URL}/login`,
    REGISTER: `${URL}/register`,

    MOBILE_LOGIN: `${URL}/mobile_login`,

    SEND_OTP: `${URL}/send_otp`,


    LOGOUT: `${URL}/logout`,

    //Change password
    CHANGE_PASSWORD: `${URL}/change_password`,

    // User balance
    GET_BALANCE: `${URL}/balance`,

    //Client announcement
    GET_CLIENT_ANNOUNCEMENT: `${URL}/client_announcement`,

    // USER BET
    PLACE_BET: `${URL}/place_bet`,
    OPEN_BETS: `${URL}/open_bets`,

    // GET_ALL_SPORTS: `${URL}/get_all_sports`,
    GET_ALL_SPORTS: `${URL}/get_all_sports_count`,

    GET_ALL_SPORTS_COUNT: `${URL}/get_all_sports_count`,

    // Fixtures
    GET_INPLAY_FIXTURES: `${URL}/inplay_fixtures`,
    GET_UPCOMING_FIXTURES: `${URL}/upcoming_fixtures`,
    GET_MATKA_INPLAY_FIXTURES: `${URL}/matka_inplay_fixtures`,
    GET_PINNED_MATCHES: `${URL}/get_pinned_matches`,
    PIN_MATCH: `${URL}/pin_match`,


    SESSION_BOOK: `${URL}/session_book`,

    //Match detail
    GET_MATCH_DETAIL: `${URL}/match_detail`,

    DECLARED_SESSION: `${URL}/declared_session`,

    CALCULATE_MARKET_WINLOSS: `${URL}/calculate_market_winloss`,
    CALCULATE_FANCY_WINLOSS: `${URL}/calculate_fancy_winloss`,

    //Ledger details
    LEDGER_DETAIL: `${URL}/ledger_detail`,

    // Maintenance mode
    MAINTENANCE_MODE: `${URL}/maintenance_mode`,

    //Before Login api's
    NON_LOGIN_INPLAY_FIXTURES: `${URL}/get_inplay_fixtures`,
    NON_LOGIN_UPCOMING_FIXTURES: `${URL}/get_upcoming_fixtures`,
    NON_LOGIN_MATCH_DETAIL: `${URL}/event_detail`,

    UPDATE_ODD_DIFF: `${URL}/update_odd_diff`,
    UPDATE_STACK_VALUES: `${URL}/update_stake_values`,

    CASINO_LOGIN: `${URL}/casino_login`,
    DIAMOND_CASINO_LOGIN: `${URL}/diamond_casino_login`,

    USER_LIMIT: `${URL}/user_limit`,

    GET_SPORT_LEAGUES: `${URL}/get_sport_leagues`,

    GET_LEAGUE_EVENTS: `${URL}/get_league_events`,

    MASTER_DATA: `${URL}/get_master_data`,

    PAYEMTN_INFO: `${URL}/payment_info`,
    DEPOSIT_REQUEST: `${URL}/deposit_request`,

    USER_BET_LIST: `${URL}/user_bet_list`,

    BANNER_LIST: `${URL}/banner_list`,

    PAY_IN: `${URL}/pay_in`,

    SAVE_BANK_DETAIL: `${URL}/save_bank_detail`,
    USER_BANK_DETAIL: `${URL}/user_bank_detail`,
    WITHDRAW_REQUESTS: `${URL}/withdraw_request`,
    LIST_WITHDRAW_REQUESTS: `${URL}/list_withdraw_requests`,

    BONUS_OFFERS: `${URL}/bonus_offers`,
    BONUS_STATEMENT: `${URL}/bonus_statement`,

    LIST_DEPOSIT_REQUEST: `${URL}/list_deposit_request`,

    DOMAIN_DATA: `${URL}/domain_data`,
}

export default {
    ...API
};

import { casinoLogin, getAwsCasino, getMasterData } from 'App/_redux/_services'
import React, { Fragment, useEffect, useState } from 'react'
import _ from "App/helper/_";
import { useDispatch, useSelector } from 'react-redux';
import { toggleAuthModalRequest } from 'App/_redux/_actions';
import { Link } from 'react-router-dom';

const CasinoSidebar = () => {
    const dispatch = useDispatch();
    const { currentUser } = useSelector((state) => state.auth);
    const { masterData } = useSelector((state) => state.match);

    const [state, setStateValue] = useState({
        casinoList: [],
        casinoUrl: null
    })


    const setState = (obj, callback) => {
        setStateValue((s) => ({ ...s, ...obj }));
        if (callback) callback();
    };

    const fetchCasino = async (provider_code) => {
        // let param = { provider_code }
        // try {
        //     const res = await casinoLogin(param);
        //     if (res.data.url) {
        //         setState({ casinoUrl: res.data.url })
        //     }

        // } catch (error) {
        //     console.log("err")
        // }
    }


    const toggleAuthModal = (type) => {
        dispatch(toggleAuthModalRequest(type))
    }

    const { casinoUrl } = state
    return (
        <Fragment>
            {casinoUrl == null && masterData && masterData.poular_casinos && masterData.poular_casinos.length > 0 ?
                <Fragment>
                    <div className="flex gap-[18px] flex-wrap pb-12 pl-[10px] items-center justify-center">
                        <div className="text-[15.75px] font-semibold  h-[45px] bg-active text-active-foreground flex items-center justify-center w-full">
                            Casino Games
                        </div>
                        <div className="grid grid-cols-2 gap-2 w-full">
                            {masterData.poular_casinos.map((item, key) => {
                                return (
                                    <Fragment key={key}>
                                        <Link to={`/casino/${item.providerCode}/${item.code}`} className="rounded-[3.4px] overflow-hidden " onClick={() => { !_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username ? fetchCasino(item.providerCode) : toggleAuthModal("LOGIN") }}>
                                            <img src={item.thumb} alt="" className="h-full w-full" />
                                        </Link>
                                    </Fragment>
                                )
                            })}
                        </div>
                    </div>
                </Fragment> :
                <Fragment>
                    <iframe className='w-full h-screen' src={casinoUrl} frameborder="0"></iframe>
                </Fragment>}
        </Fragment>
    )
}

export default CasinoSidebar
import { authConstants } from "../../_constants";
import { userLogin, logout, changePasswordLogin, sideMaintenance, MobileLogin, registerUser, domainData } from "../../_services";
import { toast } from "react-hot-toast";


export const toggleAuthModalRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: authConstants.TOGGLE_LOGIN_FORM_REQUEST, data: data });
  };
};

export const domainDataRequest = (postData, callback) => {
  return (dispatch) => {
    dispatch({ type: authConstants.DOMAIN_DATA_REQUEST, data: {} });
    domainData(postData)
      .then((response) => {
        if (response.data) {
          callback(response);
          dispatch({ type: authConstants.DOMAIN_DATA_SUCCESS, data: response.data });
        } else {
          toast.error(response.error);
        }
      })
      .catch((error) => {
        callback(false);
        dispatch({ type: authConstants.FAILED_RESPONSE, data: error });
      });
  };
};

export const loginUser = (postData, callback) => {
  return (dispatch) => {
    dispatch({ type: authConstants.LOGIN_REQUEST, data: {} });
    userLogin(postData)
      .then((response) => {
        if (response.data.username) {
          // toast.success("Login successfully");
          callback(response);
          dispatch({ type: authConstants.LOGIN_SUCCESS, data: response.data });
        } else {
          toast.error(response.error);
        }
      })
      .catch((error) => {
        callback(false);
        dispatch({ type: authConstants.FAILED_RESPONSE, data: error });
      });
  };
};

export const registerUserRequest = (postData, callback) => {
  return (dispatch) => {
    dispatch({ type: authConstants.REGISTER_REQUEST, data: {} });
    registerUser(postData)
      .then((response) => {
        if (response.data.username) {
          // toast.success("Login successfully");
          callback(response);
          dispatch({ type: authConstants.REGISTER_SUCCESS, data: response.data });
        } else {
          callback(response);
          // toast.error(response.error);
        }
      })
      .catch((error) => {
        callback(error);
        dispatch({ type: authConstants.FAILED_RESPONSE, data: error });
      });
  };
};
export const loginUserByMobile = (postData, callback) => {
  return (dispatch) => {
    dispatch({ type: authConstants.LOGIN_REQUEST, data: {} });
    MobileLogin(postData)
      .then((response) => {
        if (response.data.username) {
          // toast.success("Login successfully");
          callback(response);
          dispatch({ type: authConstants.LOGIN_SUCCESS, data: response.data });
        } else {
          toast.error(response.error);
        }
      })
      .catch((error) => {
        callback(false);
        dispatch({ type: authConstants.FAILED_RESPONSE, data: error });
      });
  };
};

export const logoutUser = (callback) => {
  return (dispatch) => {
    dispatch({ type: authConstants.LOGOUT_REQUEST, data: {} });
    logout()
      .then((response) => {
        // toast.success("Logout successfully");
        callback(true);
        dispatch({ type: authConstants.LOGOUT_SUCCESS, data: {} });
      })
      .catch((error) => {
        callback(false);
        dispatch({ type: authConstants.FAILED_RESPONSE, data: error });
      });
  };
};
// change password
export const changePassword = ({ old_password, new_password, confirm_password }, callback) => {
  return (dispatch) => {
    dispatch({ type: authConstants.CHANGE_PASSWORD_REQUEST, data: {} });
    changePasswordLogin(old_password, new_password, confirm_password)
      .then((response) => {
        toast.success("Change Password successfully");
        callback(true);
        dispatch({ type: authConstants.CHANGE_PASSWORD_SUCCESS, data: response.data });
      })
      .catch((error) => {
        callback(false);
        dispatch({ type: authConstants.FAILED_RESPONSE, data: error });
      });
  };
};


export const loginMaintenance = (postData, callback) => {
  return (dispatch) => {
    dispatch({ type: authConstants.MAINTENACE_REQUEST, data: {} });
    sideMaintenance(postData)
      .then((response) => {

        callback(response);
        dispatch({ type: authConstants.MAINTENACE_SUCCESS, data: response });
      })
      .catch((error) => {
        callback(false);
        dispatch({ type: authConstants.MAINTENACE_RESPONSE, data: error });
      });
  };
};

export const updateUserBalance = (updatedcurrentUser) => {
  return (dispatch) => {
    dispatch({ type: authConstants.UPDATE_USER_BALANCE, data: updatedcurrentUser });
  };
};
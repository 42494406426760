import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
// import AlertDialog from "./AlertDialog";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { commentaryType, getDateAndTime, getLeftBazarTime } from "App/utils";
import { Button, Modal } from "react-bootstrap";
import MatkaHeader from "./MatkaHeader";
import Form from "react-bootstrap/Form";
import { MatkaBetUI } from "./MatkaBetUI";
import { getMarketSessionBet, getclientBalance } from "App/_redux/_services";
import { getMatchDetailRequest } from "App/_redux/_actions";
import "./matka.css";
import { MatkaBetList } from "./MatkaBetList";
import { DotLoader } from "react-spinners";
export const MatkaDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [state, setAllStateValue] = useState({
    betAmount: 0,
    redirect: "",
    width: window.innerWidth,
    height: window.innerHeight,
    bookmakerType: 0,
    bettingType: 0,
    price: 0,
    displayPrice: 0,
    marketId: "",
    selectionId: "",
    toggleBetForm: false,
    name: "",
    marketBetData: "",
    betTotalRecords: 0,
    sessionBetData: "",
    eventId: "",
    shown: false,
    showAlertDialog: false,
    alertType: "",
    alertMessage: "",
    commentaryData: "",
    timer: 0,
    betPlacedClick: false,
  });
  const [matchDetail, setMatchDetails] = useState({});
  const [allActiveFancySessions, setAllActiveFancySessions] = useState([]);
  const [oddsStack, setOddsStack] = useState([]);
  const [odd_diff, setOddDiff] = useState("");
  const [openSettingModal, setOpenSettingModal] = useState(false);
  const [callBalanceAPI, setCallBalanceAPI] = useState(false);
  const [marketType, setActiveMarket] = useState(
    localStorage.getItem("matka_market_id")
  );
  const [matkaType, setActiveMatka] = useState("");
  const [fetchBetList, fetchMatkaBetList] = useState(false);
  const [betClosed, setBetClosed] = useState(false);
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    return () => {
      setMatchDetails({});
      localStorage.removeItem("matka_market_id");
      window.removeEventListener("resize", updateDimensions);
      if (state.socketValue) {
        setMatchDetails({});
      }
    };
  }, []);

  useEffect(() => {
    if (params?.event_id) {
      loadData();
    }
  }, [params?.event_id]);

  useEffect(() => {
    if (state.eventId && params.event_id !== state.eventId) {
      setState({ eventId: params.event_id });
      window.location.reload();
    }
  }, [params.event_id]);

  useEffect(() => {
    getclientBalanceData();
  }, [callBalanceAPI]);

  const getclientBalanceData = async () => {
    getclientBalance().then((response) => {
      if (response && response.data) {
        setOddsStack(
          response.data.odds_stack ? response.data.odds_stack.split(",") : []
        );
        setOddDiff(response?.data?.odd_diff ? response.data.odd_diff : "");
      }
    });
  };

  useEffect(() => {
    if (!state.toggleBetForm) {
      clearValues();
    }
  }, [state.toggleBetForm]);

  const setState = (obj, callback) => {
    setAllStateValue((s) => ({ ...s, ...obj }));
    if (callback) callback();
  };

  const clearValues = () => {
    setState({
      betAmount: 0,
      bookmakerType: 0,
      bettingType: 0,
      price: 0,
      displayPrice: 0,
      marketId: "",
      selectionId: "",
      name: "",
    });
  };
  const updateDimensions = () => {
    setState({ width: window.innerWidth, height: window.innerHeight });
  };

  const loadData = () => {
    // window.addEventListener("resize", updateDimensions);
    fetchMatchDetailData();
  };

  useEffect(() => {
    fetchMarketBet();
  }, [matkaType, fetchBetList]);

  const fetchMatchDetailData = () => {
    const { event_id } = params;
    if (event_id) {
      dispatch(
        getMatchDetailRequest({ event_id }, (res) => {
          matchDetailParse(res);
          setState({ eventId: event_id });
        })
      );
    } else {
      setState({ eventId: event_id });
    }
  };

  const matchDetailParse = (data) => {
    let finalData = [];
    finalData = data.market;
    data.market.map((market, markey) => {
      market.runners.map((run, key) => {
        market.runner_odds.find((odd) => {
          if (run.selectionId === odd.selectionId) {
            run["runner_odd"] = odd;
            finalData[markey].runners[key] = run;
          }
        });
      });
    });
    if (data?.event?.sport_id == 1) {
      let allMarket = finalData;
      for (let i = 0; i < allMarket.length; i++) {
        const marketRunner = allMarket[i].runners;
        const updatedRunner = [];
        let drawRunner = null;
        for (let j = 0; j < marketRunner.length; j++) {
          if (marketRunner[j].runnerName == "The Draw") {
            drawRunner = marketRunner[j];
          } else {
            updatedRunner.push(marketRunner[j]);
          }
        }
        if (drawRunner) {
          updatedRunner.push(drawRunner);
        }
        allMarket[i].runners = updatedRunner;
      }
      finalData = allMarket;
    }
    data.market = finalData;
    const liveData = data.event.score ? JSON.parse(data.event.score) : "";
    const parseData = parseLiveScore(liveData, data); //ig
    data.live_msg = parseData.liveScoreMsg;
    data.live_score1 = parseData.live_score1;
    data.live_score2 = parseData.live_score2;
    handleAllactiveFancy(state.allActiveFancySessions, data);
  };

  const parseLiveScore = (liveData, data) => {
    //
    let finalData = { live_score1: null, live_score2: null, liveScoreMsg: "" };

    if (liveData) {
      let liveScore = liveData.data;
      let liveScore1 = "";
      let liveScore2 = "";
      let liveScoreMsg = "";
      let team1 = "";
      let team2 = "";
      try {
        const runners = data.market[0].runners;
        team1 = runners[0].runnerName;
        team2 = runners[1].runnerName;
      } catch (error) { }
      liveScore1 = team1 + " (" + liveScore.spnnation1 + ") ";
      liveScore2 = team2 + " (" + liveScore.spnnation2 + ") ";

      if (liveScore.score1 && liveScore.score1 != "") {
        liveScore1 += liveScore.score1 + " Ovs";
      } else {
        liveScore1 += " 0-0 (0.0) Ovs";
      }

      if (liveScore.score2 && liveScore.score2 != "") {
        liveScore2 += liveScore.score2 + " Ovs";
      } else {
        liveScore2 += " 0-0 (0.0) Ovs";
      }
      let rrr = "";
      if (liveScore.spnreqrate1 != "" || liveScore.spnreqrate2 != "") {
        rrr = liveScore.spnreqrate1
          ? liveScore.spnreqrate1
          : liveScore.spnreqrate2;
        if (rrr != "") {
          rrr = " RRR: " + rrr;
        }
      }
      liveScoreMsg = liveScore.spnmessage + rrr;
      finalData = {
        live_score1: liveScore.activenation1 == "1" ? liveScore1 : liveScore2,
        live_score2: liveScore.activenation2 == "1" ? liveScore1 : liveScore2,
        liveScoreMsg: liveScoreMsg,
      };
    }
    return finalData;
  };

  const handleAllactiveFancy = (
    allActiveFancy = allActiveFancySessions,
    matchData
  ) => {
    let fancyData = matchData?.fancy.filter((itemFacny) =>
      allActiveFancy.length
        ? allActiveFancy.includes(JSON.stringify(itemFacny.session_id))
        : true
    );
    matchData.fancy = fancyData;
    setBetClosed(
      getLeftBazarTime(
        getDateAndTime("YYYY-MM-DD HH:mm:ss", matchData?.event?.open_date)
      )?.bet_sec > matchData?.event?.bet_close_time
        ? 1
        : 0
    );
    setMatchDetails(matchData);
    setActiveMarket(
      localStorage.getItem("matka_market_id")
        ? localStorage.getItem("matka_market_id")
        : matchData?.market[0]?.market_name
    );
    setIsloading(false);
    setAllActiveFancySessions(allActiveFancy);
  };

  const fetchMarketBet = () => {
    const { event_id } = params;
    let sendData = {};
    sendData.items_perpage = 100;
    sendData.current_page = 1;
    sendData.filter = 1;
    sendData.event_id = event_id;
    sendData.market_name = matkaType;

    getMarketSessionBet(sendData)
      .then((response) => {
        if (response && response?.data?.open_bets) {
          const betData =
            response?.data?.open_bets.length > 0
              ? response?.data?.open_bets.reduce((itemArray, item) => {
                const foundItem = itemArray.find(
                  (element) => element.market_name === item.market_name
                );

                if (foundItem) {
                  foundItem.list.push(item);
                } else {
                  itemArray.push({
                    market_name: item.market_name,
                    list: [item],
                  });
                }

                return itemArray;
              }, [])
              : [];
          setState({
            marketBetData: betData,
            betTotalRecords: response.data.total_records,
          });
        } else {
          setState({ marketBetData: [], betTotalRecords: 0 });
        }
      })
      .catch((error) => {
        setState({ marketBetData: [], betTotalRecords: 0 });
      });
  };

  const updateTimer = () => {
    if (state.timer == 10) {
      setState({ timer: 0, toggleBetForm: false });
    } else {
      setTimeout(() => {
        let time = state.timer;

        time += 1;
        setState({ timer: time }, () => {
          if (!state.betPlacedClick) {
            updateTimer();
          }
        });
      }, 1000);
    }
  };

  const getMarketList = (allMarket = []) => {
    let filteredList = [];
    for (let i = 0; i < allMarket.length; i++) {
      if (allMarket[i].market_type == 6 || allMarket[i].market_type == 7) {
        filteredList.push(allMarket[i]);
      }
    }
    return filteredList;
  };

  return (
    <>
      <div className="px-2 sm:px-3 flex flex-wrap gap-x-[13px]">
        <div className="pt-3 basis-full md:basis-[calc(100%)] lg:basis-[calc(100%-518px)]">
          <MatkaHeader
            betClosed={betClosed}
            setBetClosed={setBetClosed}
            matchDetail={matchDetail}
          />
          {isLoading ? (
            <div className="flex justify-center items-start text-center py-12  h-screen">
              <div className="px-24 py-8 bg-[#F5F5F5] dark:bg-[#22262A] flex justify-center items-center">
                <DotLoader color="#0b81db" />
              </div>
            </div>
          ) : !isLoading && matchDetail?.market?.length > 0 ? (
            <>
              {matchDetail?.event?.upline_hide_event === 1 ? (
                <div className="row matchBox-Row">
                  <h3
                    className="in-play-row-left"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Match hide, please contact to uplines.
                  </h3>
                </div>
              ) : (
                <>
                  {matchDetail?.market?.length > 0 && (
                    <div className="matka_menu_box text-color">
                      <div className="mtk_openAkda">
                        <Form.Control
                          as="select"
                          className="focus:shadow-[0px_0px_0px_0px_rgba(13,110,253,0)]"
                          onChange={(e) => setActiveMarket(e.target.value)}
                          defaultValue={marketType}
                          value={marketType}
                        >
                          {matchDetail?.market?.map((market) => (
                            <option
                              key={market?.market_name}
                              value={market?.market_name}
                              className={
                                "matka_menu_item " +
                                (marketType === market?.market_name
                                  ? "active"
                                  : "")
                              }
                            >
                              {market?.market_name}
                            </option>
                          ))}
                        </Form.Control>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="19"
                          viewBox="0 0 18 19"
                          fill="none"
                        >
                          <path
                            d="M9.0926 12.0002L15.1289 5.97199C15.1875 5.91308 15.2573 5.86654 15.3342 5.8351C15.4112 5.80367 15.4936 5.788 15.5767 5.789C15.6598 5.79001 15.7418 5.80768 15.8179 5.84097C15.8941 5.87425 15.9627 5.92248 16.0199 5.98279C16.137 6.10615 16.2014 6.27031 16.1994 6.44038C16.1974 6.61046 16.1291 6.77304 16.0091 6.89359L9.5264 13.3673C9.46824 13.4259 9.39898 13.4722 9.32267 13.5037C9.24637 13.5352 9.16456 13.5512 9.08202 13.5507C8.99948 13.5502 8.91787 13.5332 8.84195 13.5008C8.76604 13.4684 8.69735 13.4212 8.6399 13.3619L1.9844 6.54799C1.86661 6.42601 1.80078 6.26306 1.80078 6.09349C1.80078 5.92392 1.86661 5.76097 1.9844 5.63899C2.04228 5.57939 2.11154 5.53201 2.18806 5.49965C2.26458 5.46729 2.34682 5.45062 2.4299 5.45062C2.51299 5.45062 2.59522 5.46729 2.67175 5.49965C2.74827 5.53201 2.81752 5.57939 2.8754 5.63899L9.0926 12.0002Z"
                            fill="white"
                          />
                        </svg>
                      </div>

                      <div className="win_rate">
                        <Form.Label>WIN RATE</Form.Label>:{" "}
                        <span
                          style={{
                            margin: "0",
                          }}
                        >
                          {
                            getMarketList(
                              matchDetail?.market?.filter(
                                (item) => item?.market_name === marketType
                              )
                            )[0]?.winning_multiple
                          }
                        </span>
                      </div>
                    </div>
                  )}

                  <MatkaBetUI
                    event={matchDetail?.event}
                    marketType={marketType}
                    marketList={getMarketList(
                      matchDetail?.market?.filter(
                        (item) => item?.market_name === marketType
                      )
                    )}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <div className="row matchBox-Row">
                <h3
                  className="in-play-row-left"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Market Closed
                </h3>
              </div>
            </>
          )}
        </div>
        <MatkaBetList
          marketBetData={state.marketBetData}
          betClosed={betClosed}
          event={{ ...matchDetail?.event, market_name: marketType }}
          fetchMatkaBetList={fetchMatkaBetList}
        />
      </div>
      {/* {state.showAlertDialog && (
        <AlertDialog
          showModel={state.showAlertDialog}
          message={state.alertMessage}
          onClose={() => setState({ showAlertDialog: false })}
          alertType={state.alertType}
        />
      )} */}
      {openSettingModal && (
        <Modal
          className="setting_modal"
          size="lg"
          show={openSettingModal}
          onHide={() => setOpenSettingModal(false)}
          backdrop="static"
          keyboard={false}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "10px",
              marginRight: "10px",
            }}
          >
            <Button variant="danger" onClick={() => setOpenSettingModal(false)}>
              Close
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

// import axios from "axios";
import axiosAuth from "App/lib/axios";
import { toast } from "react-hot-toast";
import config from "App/lib/app-config";
// import { getStorage } from "../utils";
import API from "App/lib/api-urls";
import axios from "axios";

export const getAllSport = (data) => {
  return axiosAuth
    .post(
      // config.api_baseurl + "get_all_sports",
      API.GET_ALL_SPORTS, data,
      { headers: config.headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getInPlayFixture = (data) => {
  return axiosAuth
    .post(API.GET_INPLAY_FIXTURES, data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getUpcomingFixture = (data) => {
  return axiosAuth
    .post(API.GET_UPCOMING_FIXTURES, data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};
export const getPinnedMatches = (data) => {
  return axiosAuth
    .post(API.GET_PINNED_MATCHES, data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};
export const pinMatch = (data) => {
  return axiosAuth
    .post(API.PIN_MATCH, data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getMatchDetail = (data) => {
  return axiosAuth
    .post(API.GET_MATCH_DETAIL, data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getclientAnnouncement = (data) => {
  return axiosAuth
    .post(
      API.GET_CLIENT_ANNOUNCEMENT,
      {},
      { headers: config.headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const betPlace = (data) => {
  return axiosAuth
    .post(API.PLACE_BET, data, { headers: config.headers })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      // throw err;
      return err;
    });
};


export const getUserbalance = (data = {}) => {
  return axiosAuth
    .post(API.GET_BALANCE, data, { headers: config.headers })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getPaymentInfo = (data = {}) => {
  return axiosAuth
    .post(
      API.PAYEMTN_INFO, data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};
export const depositRequest = (data = {}) => {
  return axiosAuth
    .post(
      API.DEPOSIT_REQUEST, data, {
      headers: { "Content-Type": "multipart/form-data", },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      return err
      // throw err;
    });
};


export const getMarketPosition = (data = {}) => {
  return axiosAuth
    .post(API.CALCULATE_MARKET_WINLOSS, data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getSessionPosition = (data = {}) => {
  return axiosAuth
    .post(API.CALCULATE_FANCY_WINLOSS, data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getMarketSessionBet = (data = {}) => {
  return axiosAuth
    .post(API.OPEN_BETS, data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getDeclaredSession = (data = {}) => {
  return axiosAuth
    .post(API.DECLARED_SESSION, data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getclientBalance = (data) => {
  return axiosAuth
    .post(
      API.GET_BALANCE, {}
      , { headers: config.headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};


export const updateOddDiff = (data) => {
  return axiosAuth
    .post(
      API.UPDATE_ODD_DIFF, data
      , { headers: config.headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const updateStakeValue = (data) => {
  return axiosAuth
    .post(
      API.UPDATE_STACK_VALUES, data
      , { headers: config.headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const casinoLogin = (data = {}) => {
  return axiosAuth
    .post(
      API.CASINO_LOGIN, data,
      { headers: config.headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const diamondCasinoLogin = (data = {}) => {
  return axiosAuth
    .post(
      API.DIAMOND_CASINO_LOGIN, data
      , { headers: config.headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const supernowaLogin = () => {
  return axiosAuth
    .post(
      config.api_baseurl + "supernowa_login"
      , { headers: config.headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getSessionBook = (data = {}) => {
  return axiosAuth
    .post(API.SESSION_BOOK, data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const placeMatkaBet = (data = {}) => {
  return axiosAuth
    .post(config.api_baseurl + "/matka_place_bet", data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};


export const cancelMatkaBet = (data = {}) => {
  return axiosAuth
    .post(config.api_baseurl + "/cancel_bet", data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getUserLimit = (data = {}) => {
  return axiosAuth
    .post(API.USER_LIMIT, data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getMatkaCategoryResult = (data = {}) => {
  return axiosAuth
    .post(config.api_baseurl + "/get_matka_category_result", data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getTvURL = (data = {}) => {
  return axiosAuth
    .post(config.api_baseurl + "get_tv_url", data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getMatkaAnnouncement = (data = {}) => {
  return axiosAuth
    .post(config.api_baseurl + "matka_announcement", data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const userBetList = (data) => {
  return axiosAuth
    .post(API.USER_BET_LIST, data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message == "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const instantPayRequest = (data) => {
  return axiosAuth
    .post(
      API.PAY_IN, data,
      { headers: config.headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getBonusStatement = (data) => {
  return axiosAuth
    .post(
      API.BONUS_STATEMENT, data,
      { headers: config.headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getBonusOffers = (data) => {
  return axiosAuth
    .post(
      API.BONUS_OFFERS, data,
      { headers: config.headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};
export const depositListRequest = (data) => {
  return axiosAuth
    .post(
      API.LIST_DEPOSIT_REQUEST, data,
      { headers: config.headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getBanners = (data) => {
  return axiosAuth
    .post(API.BANNER_LIST, data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message == "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};


export const getSportsLeagues = (data) => {
  return axiosAuth
    .get(
      API.GET_SPORT_LEAGUES, { params: data }
      , { headers: config.headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getLeagueEvents = (data) => {
  return axiosAuth
    .get(
      API.GET_LEAGUE_EVENTS, { params: data }
      , { headers: config.headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getAwsCasino = (data = {}) => {
  return axios
    .get(config.s3_url + "static/" + config.s3_prefix + "app-master-data.json", data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return "error";
    });
};

export const getMasterData = (data = {}) => {
  return axiosAuth
    .get(API.MASTER_DATA, data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};


